import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import './ChiSiamo.scss';
import './Consiglio.scss';

import BloccoEditoriale from '../components/BloccoEditoriale';
import ScrollingPayoff from '../components/ScrollingPayoff';
import ConsiglioComponent from '../components/Consiglio';
import Gallery from '../components/Gallery';
import { TeamMemberDto } from '../classes/dto/TeamMember';
import { StrapiResponse } from '../services/strapi.service';
import { RepositoryService } from '../services/repository.service';
import { absoluteUrl } from '../services/utils';
import { MiltonDescriptionDto } from '../classes/dto/MiltonDescription';
import { Media } from '../classes/dto/ContentType';
import { Parallax } from 'react-scroll-parallax';

const memberService = RepositoryService.newRepository<TeamMemberDto>("miltons");
const miltonDescriptionService = RepositoryService.newRepository<MiltonDescriptionDto>("chi-siamo");

function ChiSiamo() {
  
  const [memberList, setMemberList] = useState<TeamMemberDto[]>([]);
  const [descriptionData, setDescriptionData] = useState<MiltonDescriptionDto | null>(null);

  useEffect(() => {
    fetchMemberList();
    fetchMiltonsDescription();
  }, []);

  const fetchMemberList = async () => {
    try {
      const response: StrapiResponse<TeamMemberDto[] | null> = await memberService.fetchList('populate=*&sort[0]=id:asc');
      if (response && response.data) {
        setMemberList(response.data || []);
      } else {
        console.error("Failed to fetch member list:", response?.error);
      }
    } catch (error) {
      console.error("Failed to fetch member list:", error);
    }
  };
  

  const fetchMiltonsDescription = async () => {
    try {
      const response: StrapiResponse<MiltonDescriptionDto> = await miltonDescriptionService.fetch('', 'populate=*')
      if (response && response.data) {

        setDescriptionData(response.data);
      } else {
        console.error("Failed to fetch consiglio data:", response?.error);
      }
    } catch (error) {
      console.error("Failed to fetch consiglio data:", error);
    }
  };

  const coverUrl = absoluteUrl(descriptionData?.attributes?.Copertina?.data?.attributes?.url || '');
  const title1 = descriptionData?.attributes?.Titolo1 || '';
  const text1 = descriptionData?.attributes?.Testo1 || '';
  const title2 = descriptionData?.attributes?.Titolo2 || '';
  const text2 = descriptionData?.attributes?.Testo2 || '';
  const title3 = descriptionData?.attributes?.Titolo3 || '';
  const text3 = descriptionData?.attributes?.Testo3 || '';
  const galleryData = descriptionData?.attributes?.Galleria?.data;
  // const decriptionGalleryItems: string | any[] = [];
  const decriptionGalleryItems = galleryData
    ? galleryData.map((image: Media) => ({
        url: absoluteUrl(image.attributes?.url || ''),
        caption: image.attributes?.caption || '',
      }))
    : [];

  const memberIterator = (member: TeamMemberDto, index: number) => {
    // Extract constants from member data
    const copertina = member.attributes?.Copertina;
    const nome = member.attributes?.Nome;
    const descrizione = member.attributes?.Descrizione ? member.attributes?.Descrizione : "";
    const galleria = member.attributes?.Galleria;
    let memberGalleryItems: {
      url: string;
      caption: string;
    }[] = [];
    if (galleria && galleria.data) {
      memberGalleryItems = galleria.data.map((galleryImg, index) => {
        // Extract necessary properties from each item in the galleria.data array
        const url = absoluteUrl(galleryImg.attributes.url);
        const caption = galleryImg.attributes.caption ? galleryImg.attributes.caption : "";
    
        return {
          url,
          caption,
        };
      });
      //filter memberGalleryItems to maximum 3 items
      memberGalleryItems = memberGalleryItems.filter((item, index) => index < 3);
    }

    if (!copertina) return null;

    return (
      <div className="OneMilton" key={index}>
        <div className="ScrollingPhoto f-1">
          <div className="Photo" key={index}>
            <img className='full-cover' src={absoluteUrl(copertina.data.attributes.url)} alt={copertina?.data.attributes.caption ? copertina?.data.attributes.caption : ""} />
          </div>
        </div>
        <div className="Description f-1">
          <BloccoEditoriale
            occhielloText="I miltons"
            occhielloClass="accent"
            titoloSize="bigger"
            titoloText={nome}
            text={[descrizione]}
            textSize='big'
          />
          {
          memberGalleryItems.length > 0 
          && 
          <Gallery className='PhotoDescriptionsGallery' items={memberGalleryItems} />
  }{
          memberGalleryItems.length > 0 
          && 
          <div className="PhotoDescriptions">
            {
              memberGalleryItems.map((photo, index) => (
                <div className="PhotoDescription" key={index}>
                  <Parallax translateY={[100*index,0]} easing='easeOutExpo'>
                    <img className='full-cover' src={photo.url} alt={photo.caption} />
                  </Parallax>
                </div>
              ))
            }
          </div>
          }
        </div>
      </div>
    );
  }
  const nogallery = decriptionGalleryItems.length > 0 ? '' : 'no-gallery';

  return (
    <div className="ChiSiamoPage bg-ligh">
      <div className="Team w-100">
        { memberList.map( memberIterator ) }
      </div>
      <ScrollingPayoff />
      {descriptionData && <div className="ConsiglioContainer relative w-100 d-f lines lines-black">
        <div className="d-f f-1">
          <div className="sticky-container bg-dark w-100">
            <ConsiglioComponent
              className="w-100 sticky"
              id={0}
              header={{
                title: "",
                cover: coverUrl,
              }}
              date={""}
              authorName={""}
              imgUrl={coverUrl}
            />
          </div>
        </div>
        <div className="d-f f-2 f-col">
          <BloccoEditoriale
            className="padding first"
            titoloText={title1}
            titoloTag="div"
            titoloSize="big"
            text={[text1]}
            textSize="big"
          />
          <BloccoEditoriale
            className={`padding bg-dark ${nogallery ? 'no-gallery1' : ''}`}
            titoloText={title2}
            titoloTag="div"
            titoloSize="big"
            text={[text2]}
            textSize="big"
          />
          {decriptionGalleryItems.length > 0 && <Gallery className='bg-accent' items={decriptionGalleryItems} />}
          <BloccoEditoriale
            className={`padding bg-dark ${nogallery ? 'no-gallery2' : ''}`}
            titoloText={title3}
            titoloTag="div"
            titoloSize="big"
            text={[text3]}
            textSize="big"
          />
        </div>
      </div>}
      <Footer />
    </div>
  );
}

export default ChiSiamo;
