import React, { useEffect, useState } from "react";
import { AdviceDto } from "../classes/dto/Advice"; // Update the import path to match your directory structure
import { RepositoryService } from "../services/repository.service";
import { StrapiResponse } from "../services/strapi.service";
import Consiglio from "./Consiglio"; // Update the import path to match your directory structure
import "./Consigli.scss"; // Update the import path to match your directory structure
import { absoluteUrl } from "../services/utils";

const adviceService = RepositoryService.newRepository<AdviceDto>("consigli"); // Update the endpoint to match your API

interface ConsigliProps {
  count?: number; // Optional prop to specify the number of advice items to list
  className?: string;
  childClassName?: string;
  cta?: boolean;
  excludeId?: string; // Prop to exclude events by id
}

const Consigli: React.FC<ConsigliProps> = ({ count, className, childClassName, cta, excludeId='' }) => {
  const [adviceList, setAdviceList] = useState<AdviceDto[]>([]);
  const queryParams = "populate=*&sort[0]=Data:desc" + (excludeId ? `&filters[id][$ne]=${excludeId}` : '');

  useEffect(() => {
    fetchAdviceList();
  }, []);

  const fetchAdviceList = async () => {
    try {
      const response: StrapiResponse<AdviceDto[] | null> = await adviceService.fetchListWithPagination(3, queryParams, 3);
      if (response && response.data) {
        setAdviceList(response.data || []);
      } else {
        console.error("Failed to fetch advice list:", response?.error);
      }
    } catch (error) {
      console.error("Failed to fetch advice list:", error);
    }
  };

  if (!adviceList.length) return null;

  return (
    <div className={`Consigli ${className}`}>
      <div className="fixed-text bg-text primary-font font-size-biggest white">Letture</div>
      {adviceList
        .slice(0, count)
        .map((advice, index) => {
          // console.log(advice);
          const id = advice.id;
          const title = advice.attributes?.Titolo || '';
          const coverUrl =
            advice.attributes?.Copertina?.data?.attributes?.url || '';
          const date = advice.attributes?.Data || '';
          // const text = advice.attributes?.Testo || '';
          const author = advice.attributes?.Autore || '';
          const link = advice.attributes?.Link || '';
  
          return coverUrl ? (
            <Consiglio
              id={id}
              className={childClassName}
              key={index}
              header={{
                title: title,
                cover: absoluteUrl(coverUrl),
              }}
              date={date}
              // text={text}
              authorName={author}
              imgUrl={absoluteUrl(coverUrl)}
              link={link}
              slug={advice.attributes?.slug}
            />
          ) : null;
        })}
        {
          cta && (
            <div className="cta-container">
              <a href="/consigli" className="accent secondary-font font-size-big">
                I nostri consigli di lettura
              </a>
            </div>
          )
        }
    </div>
  );
};

export default Consigli;
