import React from 'react';
import Footer from '../components/Footer';
import './NoPage.scss';
import BloccoEditoriale from '../components/BloccoEditoriale';

const NoPage: React.FC = () => {
  return (
    <div className="NoPage">
      <BloccoEditoriale
            className='d-f f-1 bg-light BloccoNoPage'
            titoloText='404'
            titoloTag='h1'
            titoloSize='bigger'
            text={['La pagina che stai cercando non esiste.']}
            textSize='big'
            cta={{
              ctaText: 'Torna alla home',
              ctaUrl: '/',
            }}
      />
      <Footer />
    </div>
  );
};

export default NoPage;
