import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { useLayoutEffect } from "react";

const texts = {
    title: "Cookie Policy",
}

const CookiePolicy = () => {
    useLayoutEffect( () => window.scrollTo(0, 0) )

    return (
        <div className="CookiePolicy Policies bg-light">
            <div className="padding">
                <h2>{texts.title}</h2>
                <p className="secondary-font secondary-font-p">
                    Ultimo aggiornamento: 03.06.23
                </p>
                <p className="secondary-font secondary-font-p">
                    La presente Cookie Policy descrive l'uso dei cookie e di altre tecnologie di tracciamento da parte di <Link to="Home">libreriamilton.it</Link> quando visiti il nostro sito web.
                </p>
                <h3>Cosa sono i cookie</h3>
                <p className="secondary-font secondary-font-p">
                    I cookie sono piccoli file di testo che vengono memorizzati sul tuo dispositivo (computer, tablet, smartphone) quando visiti un sito web.
                    Essi contengono informazioni che vengono utilizzate per migliorare l'esperienza di navigazione e per monitorare l'utilizzo del sito.
                </p>
                <h3>
                    Tipi di cookie utilizzati
                </h3>
                <p className="secondary-font secondary-font-p">
                    Utilizziamo i seguenti tipi di cookie sul nostro sito:
                    - Cookie tecnici: questi cookie sono essenziali per il corretto funzionamento del sito web e per consentirti di navigare e utilizzare le sue funzionalità. Essi includono, ad esempio, i cookie che ti consentono di accedere alle aree protette del sito o di utilizzare il carrello per gli acquisti.
                    - Cookie di analisi: utilizziamo cookie di analisi per raccogliere informazioni sull'utilizzo del sito, come il numero di visitatori, le pagine visitate e le fonti di traffico. Queste informazioni ci aiutano a migliorare il sito e a comprenderne l'efficacia delle nostre campagne di marketing.
                    - Cookie di personalizzazione: questi cookie ci consentono di personalizzare la tua esperienza di navigazione memorizzando le tue preferenze, come la lingua preferita o la regione in cui ti trovi. Ciò ci consente di offrirti contenuti più rilevanti e di rendere la tua visita più piacevole.
                    - Cookie di pubblicità: utilizziamo cookie di terze parti per mostrarti annunci pubblicitari pertinenti quando visiti il nostro sito o altri siti web. Questi cookie registrano le tue preferenze di navigazione e ci consentono di offrirti annunci mirati basati sui tuoi interessi.
                <h3>Gestione dei cookie</h3>
                </p>
                <p className="secondary-font secondary-font-p">
                    Puoi gestire le preferenze sui cookie attraverso le impostazioni del tuo browser.
                    La maggior parte dei browser ti consente di accettare o rifiutare tutti i cookie, di accettare solo determinati tipi di cookie o di ricevere una notifica quando viene inviato un cookie.
                    Tuttavia, tieni presente che la disabilitazione dei cookie potrebbe influire sul funzionamento corretto del sito e limitare alcune funzionalità.
                </p>
                {
                    // <h3>Cookie di terze parti</h3>
                    // <p>
                    //     Il nostro sito può utilizzare cookie di terze parti, inclusi servizi di analisi e piattaforme di pubblicità.
                    //     Tali cookie sono soggetti alle rispettive politiche sulla privacy delle terze parti e non sono controllati da noi.
                    //     Ti consigliamo di consultare le politiche sulla privacy di queste terze parti per ulteriori informazioni sui loro cookie e sulle modalità per gestirli.
                    // </p>
                }
                <h3>Modifiche alla Cookie Policy</h3>
                <p className="secondary-font secondary-font-p">
                    La presente Cookie Policy può essere aggiornata periodicamente.
                    Ogni modifica sarà pubblicata sul nostro sito con l'indicazione della data di entrata in vigore.
                    L'utilizzo continuato del sito da parte degli utenti dopo tali modifiche costituirà accettazione delle stesse.
                </p>
                <h3>Contatti</h3>
                <p className="secondary-font secondary-font-p">
                    Se hai domande, commenti o richieste riguardo alla nostra Cookie Policy, ti invitiamo a contattarci tramite info@libreriamilton.it.
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default CookiePolicy;

