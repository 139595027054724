import React, { useState } from "react";
import "./Partigiano.scss";
import scrittaPartigiano from "../assets/icons/scritta circolare_perchè c'è un partigiano_home.svg";

interface PartigianoProps {
  background: string;
  overlayComponent: React.ReactNode;
  className?: string;
}

const Partigiano: React.FC<PartigianoProps> = ({ background, overlayComponent, className = "" }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleCircleClick = () => {
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className={`Partigiano ${className}`}>
      <div className={`partigiano-content ${showOverlay ? "hidden" : ""}`}>
        <div className="circle-blur"></div>
        <img className="scritta" src={scrittaPartigiano} alt="cosa ci fa un partigiano?" onClick={handleCircleClick}/>
        <img className="full-cover" src={background} alt="partigianosfondo" />
      </div>
      {showOverlay && (
        <div className="partigiano-overlay bg-accent-light padding" onClick={handleCloseOverlay}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            {/* <div className="top-right close-icon" onClick={handleCloseOverlay}>
              <span>x</span>
            </div> */}
            <div className="top-right" onClick={handleCloseOverlay}>
              <div className="close-icon">
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
            {overlayComponent}
          </div>
        </div>
      )}
    </div>
  );
};

export default Partigiano;
