import React from "react";
import "./Consigli.scss";
import { AdviceDto } from "../classes/dto/Advice";
import { RepositoryService } from "../services/repository.service";
import Consiglio from "../components/Consiglio";
import { absoluteUrl } from "../services/utils";
import ScrollingPayoff from "../components/ScrollingPayoff";
import Footer from "../components/Footer";
import { usePagination } from "../libs/Pagination"; // Import the utility
import Pagination from "../components/Pagination";

const adviceService = RepositoryService.newRepository<AdviceDto>("consigli");
const queryParams = "populate=*&sort[0]=Data:desc";
// const queryParams = "consigli";
const itemsPerPage = 9;

interface ConsigliProps {}

const Consigli: React.FC<ConsigliProps> = () => {

  // Use the usePagination hook to manage data fetching and pagination
  const {
    data: adviceList,
    currentPage,
    nextPage,
    prevPage,
    hasMoreData,
  } = usePagination<AdviceDto>(adviceService, queryParams, itemsPerPage, false);

  if (!adviceList.length) return null;

  return (
    <div className="ConsigliPage lines lines-black bg-light w-100">
      <div className={`Consigli bg-light`}>
        <div className="fixed-text bg-text primary-font font-size-biggest white">
          Letture
        </div>
        {adviceList.map((advice, index) => {
          const id = advice.id;
          const title = advice.attributes?.Titolo || "";
          const coverUrl =
            advice.attributes?.Copertina?.data?.attributes?.url || "";
          const date = advice.attributes?.Data || "";
          const author = advice.attributes?.Autore || "";
          const link = advice.attributes?.Link || "";

          return coverUrl ? (
            <Consiglio
              id={id}
              className={"width-1-3"}
              key={index}
              header={{
                title: title,
                cover: absoluteUrl(coverUrl),
              }}
              date={date}
              authorName={author}
              imgUrl={absoluteUrl(coverUrl)}
              link={link}
              slug={advice.attributes?.slug}
            />
          ) : null;
        })}

        <Pagination
          currentPage={currentPage}
          prevPage={prevPage}
          nextPage={nextPage}
          hasMoreData={hasMoreData}
        />
      </div>
      <ScrollingPayoff />
      <Footer />
    </div>
  );
};

export default Consigli;
