import Footer from "../components/Footer";
import { useLayoutEffect } from "react";

const texts = {
    title: "Note legali",
}

const LegalNotes = () => {
    useLayoutEffect(() => window.scrollTo(0, 0))

    return (
        <div className="LegalNotes Policies bg-light">
            <div className="padding">
                <h2 className="">{texts.title}</h2>
                <p className="secondary-font secondary-font-p">
                    Ultimo aggiornamento: 03.06.23
                </p>
                <p className="secondary-font secondary-font-p">
                    Le presenti note legali stabiliscono i termini e le condizioni per l'utilizzo del sito web libreriamilton.it ("sito") da parte degli utenti ("utente" o "voi").
                    Accedendo al sito, accettate di essere vincolati da queste note legali.
                    Se non accettate queste condizioni, non utilizzate il sito.
                </p>
                <h3>Proprietà intellettuale</h3>
                <p className="secondary-font secondary-font-p">
                    Tutti i contenuti presenti sul sito, inclusi testi, grafiche, loghi, immagini, video, audio e qualsiasi altra forma di contenuto, sono di proprietà esclusiva di libreriamilton.it o dei suoi fornitori di contenuti e sono protetti dalle leggi sulla proprietà intellettuale.
                    È vietato copiare, riprodurre, distribuire o utilizzare in qualsiasi forma i contenuti del sito senza il consenso esplicito scritto di libreriamilton.it.
                </p>
                <h3>Limitazione di responsabilità</h3>
                <p className="secondary-font secondary-font-p">
                    Il sito e i suoi contenuti vengono forniti "così come sono" senza garanzie di alcun tipo, esplicite o implicite.
                    libreriamilton.it non offre alcuna garanzia riguardo all'accuratezza, all'affidabilità o alla completezza dei contenuti presenti sul sito.
                    L'utilizzo del sito è a vostro rischio e responsabilità.
                    libreriamilton.it non sarà responsabile per eventuali danni diretti, indiretti, consequenziali o speciali derivanti dall'utilizzo o dall'incapacità di utilizzare il sito o i suoi contenuti.
                </p>
                <h3>Collegamenti a siti di terze parti</h3>
                <p className="secondary-font secondary-font-p">
                    Il sito può contenere collegamenti a siti web di terze parti.
                    Questi collegamenti sono forniti solo per comodità e libreriamilton.it non ha alcun controllo o responsabilità sui contenuti o sulle pratiche di tali siti web.
                    L'accesso a tali siti web avviene a vostro rischio.
                </p>
                <h3>Protezione dei dati personali</h3>
                <p className="secondary-font secondary-font-p">
                    Il trattamento dei dati personali degli utenti è soggetto alla nostra Privacy Policy, che può essere consultata sul sito.
                </p>
                <h3>Modifiche alle note legali</h3>
                <p className="secondary-font secondary-font-p">
                    Le presenti note legali possono essere modificate periodicamente.
                    Eventuali modifiche saranno pubblicate sul sito con l'indicazione della data di entrata in vigore.
                    L'utilizzo continuato del sito da parte degli utenti dopo tali modifiche costituirà accettazione delle stesse.
                </p>
                <h3>Legge applicabile e foro competente</h3>
                <p className="secondary-font secondary-font-p">
                    Le presenti note legali sono regolate e interpretate in conformità alle leggi italiane.
                    Eventuali controversie derivanti dall'utilizzo del sito saranno sottoposte alla giurisdizione esclusiva dei tribunali italiani competenti.
                </p>
                <h3>Contatti</h3>
                <p className="secondary-font secondary-font-p">
                    Se avete domande o commenti sulle presenti note legali, potete contattarci tramite le informazioni fornite alla fine di questa pagina.
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default LegalNotes;

