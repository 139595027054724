import { useEffect, useState } from "react";
import { RepositoryService } from "../services/repository.service";
import { StrapiResponse } from "../services/strapi.service";
import { EventDto } from "../classes/dto/Event";

const eventService = RepositoryService.newRepository<EventDto>("eventi");

const useEventList = (queryParams: string) => {
  const [eventList, setEventList] = useState<EventDto[]>([]);

  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const response: StrapiResponse<EventDto[] | null> = await eventService.fetchListWithPagination(3, queryParams, 3);
        // const response: StrapiResponse<EventDto[] | null> = await eventService.fetchPageMock(1, 3, "eventi");
        // console.log("response", response);
        if (response && response.data) {
          setEventList(response.data || []);
        } else {
          console.error("Failed to fetch event list:", response?.error);
        }
      } catch (error) {
        console.error("Failed to fetch event list:", error);
      }
    };

    fetchEventList();
  }, [queryParams]);

  return eventList;
};

export default useEventList;
