import React from "react";
import Evento from "./Evento";
import "./Eventi.scss";
import { absoluteUrl } from "../services/utils";
import useEventList from "../hooks/useEventList";

interface EventiProps {
  count?: number;
  className?: string;
  childClassName?: string;
  showCTA?: boolean; // Prop to show/hide CTA
  showPagination?: boolean; // Prop to show/hide pagination
  excludeId?: string; // Prop to exclude events by id
}

const today = new Date().toISOString().slice(0, 10) // exclude time

const Eventi: React.FC<EventiProps> = ({
  count,
  className,
  childClassName,
  showCTA = true, // Default to true if not provided
  showPagination = true, // Default to true if not provided
  excludeId = '',
}) => {
  const queryParams = "populate=*&sort[0]=Data:asc&filters[Data][$gte]=" + today + (excludeId ? `&filters[id][$ne]=${excludeId}` : '');
  
  const eventList = useEventList(queryParams);

  if (!eventList.length) return null;

  return (
    <div className={`Eventi ${className}`}>
      <div className="fixed-text bg-text primary-font font-size-biggest white">Eventi</div>
      {eventList
        .slice(0, count)
        .map((event, index) => {
          const id = event.id;
          const title = event.attributes?.Titolo || '';
          const coverUrl =
            event.attributes?.Copertina?.data?.attributes?.url || '';
          const date = event.attributes?.Data || '';
          const location = event.attributes?.Luogo || '';
          const text1 = event.attributes?.Testo1 || '';
          const citation = event.attributes?.Aforisma || '';
          const text2 = event.attributes?.Testo2 || '';
          const organizerName = event.attributes?.Organizzatore || '';
          const link = event.attributes?.Link || '';
  
          return coverUrl ? (
            <Evento
              id={id}
              className={childClassName}
              key={index}
              header={{
                title: title,
                cover: absoluteUrl(coverUrl),
              }}
              date={date}
              addToCalendar={undefined}
              location={location}
              text1={text1}
              citation={citation}
              text2={text2}
              organizerName={organizerName}
              imgUrl={absoluteUrl(coverUrl)}
              link={link}
              slug={event.attributes?.slug}
            />
          ) : null;
        })}

      {showPagination && (
        // Render pagination here if showPagination is true
        // You can implement pagination logic here
        <div className="pagination-container">
          {/* Pagination controls */}
        </div>
      )}

      {showCTA && (
        <div className="cta-container">
          {/* Render the CTA here if showCTA is true */}
          <a href="/eventi" className="accent secondary-font font-size-big">
            Tutti gli eventi
          </a>
        </div>
      )}
    </div>
  );
};

export default Eventi;