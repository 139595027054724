import React, { useState, useEffect } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import "./Navigation.scss";

import { ReactComponent as SvgLogo } from  '../assets/logos/logo.svg';
// import logoSvgUrl from '../assets/logos/logo.svg';
import menuSvgUrl from "../assets/logos/menu.svg";

const Navigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false); // Track scrolling direction
  const [prevScrollY, setPrevScrollY] = useState(0); // Store previous scroll position
  const [isAtTop, setIsAtTop] = useState(true); // Track if we are at the top of the page
  const location = useLocation();
  const currentPath = location.pathname;

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (!isOpen) {
        setIsScrollingDown(scrollY > prevScrollY);
        setPrevScrollY(scrollY);
      }

      setIsAtTop(scrollY === 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen, prevScrollY]);

  return (
    <>
      <div className={`navigation-container ${isScrollingDown ? "scroll-down" : "scroll-up"} ${isAtTop ? '' : 'scrolling'}`}>
        <div className={`cta-logo`}>
          <a href="/">
            {/* <img className="logo-img" src={logoSvgUrl} alt="Logo" /> */}
            <SvgLogo className="logo-img" />
          </a>
        </div>
        <div className="cta-menu" onClick={toggleNavigation}>
          <div className="menu-container">
            <img className="logo-img" src={menuSvgUrl} alt="Menu" />
            <div className="cta-text light secondary-font font-size-normal">MENU</div>
          </div>
        </div>
        <div
          className={`overlay ${isOpen ? "open" : ""}`}
          onClick={toggleNavigation}
        />
        <div
          className={`menu ${isOpen ? "open" : ""} bg-dark`}
          onClick={toggleNavigation}
        >
          <div className="menu-toggle light secondary-font font-size-normal">chiudi</div>
          <nav className="Navigation">
            <ul>
              <li>
                <NavLink to="/" className={`hover-icon primary-font font-size-big ${currentPath === "/" ? "active" : "" }`}>Casa</NavLink>
              </li>
              <li>
                <NavLink to="/ChiSiamo" className={`hover-icon primary-font font-size-big ${currentPath === "/ChiSiamo" ? "active" : "" }`}>I Miltons</NavLink>
              </li>
              <li>
                <NavLink to="/Eventi" className={`hover-icon primary-font font-size-big ${currentPath === "/Eventi" ? "active" : "" }`}>Gli Eventi</NavLink>
              </li>
              <li className="contatti">
                <NavLink to="/Consigli" className={`hover-icon primary-font font-size-big ${currentPath === "/Consigli" ? "active" : "" }`}>Le Letture</NavLink>
              </li>
              <li>
                <div className="social">
                  <a className="secondary-font font-size-normal" href="https://www.instagram.com/libreriamilton/">instagram</a>
                  <a className="secondary-font font-size-normal" href="https://www.facebook.com/libreria.milton/">facebook</a>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Navigation;
