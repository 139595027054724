import React from "react";
import "./Evento.scss";
import { formatItalianDate } from "../services/utils";
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";


interface EventProps {
  id: number | string;
  className?: string;
  header: {
    title: string;
    cover?: string;
  };
  date: string;
  addToCalendar?: () => void;
  location: string;
  text1: string;
  citation: string;
  text2: string;
  organizerName?: string;
  imgUrl: string;
  gallery?: {
    images: string[];
  };
  link: string;
  slug?: string;
}

const Event: React.FC<EventProps> = ({
  id,
  className = "",
  header,
  date,
  addToCalendar,
  location,
  text1,
  citation,
  text2,
  organizerName,
  imgUrl,
  link, 
  slug
}) => {

  const { ref } = useParallax<HTMLDivElement>({ speed: 20 });

  return (
    <div className={`Evento ${className}`} ref={ref}>
      <p className="Date secondary-font">{formatItalianDate(date)}</p>
      <Link to={`/eventi/${slug ? slug : id}`}>
        {header.cover && <img className="Cover" src={header.cover} alt="Cover" />}
      </Link>
      <p className="Title primary-font font-size-normal">{header.title}</p>
      {addToCalendar && <button onClick={addToCalendar}>Add to Calendar</button>}
      <p className="Location Organizer secondary-font">
        {location} {organizerName && ` - ${organizerName}`}
      </p>
    </div>
  );
};

export default Event;
