import React, { useRef, useState, useEffect } from 'react';
import './Gallery.scss';

interface GalleryProps {
  className?: string;
  items: { url: string; caption: string }[];
}

const Gallery: React.FC<GalleryProps> = ({ items, className = '' }) => {
  const galleryScrollRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState<number | null>(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const galleryScrollElement = galleryScrollRef.current;

    if (!galleryScrollElement) return;

    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(true);
      setStartX(e.clientX);
      setScrollLeft(galleryScrollElement.scrollLeft);
      galleryScrollElement.style.cursor = 'grabbing'; // Change cursor when grabbing
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;
      const x = e.clientX - (startX || 0);
      galleryScrollElement.scrollLeft = scrollLeft - x;
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      galleryScrollElement.style.cursor = 'grab'; // Change cursor when released
    };

    galleryScrollElement.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      galleryScrollElement.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, scrollLeft, startX]);

  const handleMouseEnter = () => {
    const galleryScrollElement = galleryScrollRef.current;
    if (galleryScrollElement) {
      galleryScrollElement.style.cursor = 'grab';
    }
  };

  const handleMouseLeave = () => {
    const galleryScrollElement = galleryScrollRef.current;
    if (galleryScrollElement) {
      galleryScrollElement.style.cursor = 'auto'; // Reset cursor on leave
    }
  };

  const preventDefaultDragStart = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
  };

  const handleArrowClick = (direction: 'left' | 'right') => {
    const galleryScrollElement = galleryScrollRef.current;
    if (galleryScrollElement) {
      const offset = direction === 'left' ? -100 : 100; // Adjust the offset as needed
      galleryScrollElement.scrollLeft += offset;
    }
  };

  return (
    <div
      className={`gallery ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="arrow left primary-font font-size-big accent" onClick={() => handleArrowClick('left')}>
        {'<<'}
      </div>
      <div className="gallery-scroll" ref={galleryScrollRef}>
        {items.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img
              src={image.url}
              alt={image.caption}
              onDragStart={preventDefaultDragStart}
            />
            <div className="caption secondary-font font-size-small">{image.caption}</div>
          </div>
        ))}
      </div>
      <div className="arrow right primary-font font-size-big accent" onClick={() => handleArrowClick('right')}>
        {'>>'}
      </div>
    </div>
  );
};

export default Gallery;
