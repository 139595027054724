import { Repository } from "../services/repository.service";
import { StrapiResponse } from "../services/strapi.service";
import { useState, useEffect } from "react";


// Define the hook
export function usePagination<T>(
  repositoryService: Repository<T>,
  queryParams: string,
  itemsPerPage: number,
  mock: boolean = false,
  useLoadMore: boolean = false,
  key: string = ""
) {
  const [data, setData] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loadMoreActive, setLoadMoreActive] = useState(useLoadMore);

  const toggleLoadMore = () => {
    setLoadMoreActive((prevState) => !prevState);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let response: StrapiResponse<T[]>;
        let howMany = loadMoreActive ? 3 : itemsPerPage;

        if (mock) {
          response = await repositoryService.fetchPageMock(
            currentPage,
            howMany,
            queryParams
          );
        } else {
          response = await repositoryService.fetchPage(
            currentPage,
            howMany,
            queryParams
          );
        }

        // console.log("key", key, "response", response);

        if (response && response.data) {
          if ((useLoadMore || loadMoreActive) && currentPage > 1) {
            // Append data when using load more and not on the first page
            setData((prevData) => [...prevData, ...response.data]);
          } else {
            // Replace data when not using load more or on the first page
            setData(response.data);
          }

          if (response.data.length < howMany) {
            // If the response data length is less than howMany, it means there's no more data to load
            setHasMoreData(false);
          } else {
            setHasMoreData(true);
          }
        }
      } catch (error) {
        // Handle error here
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [currentPage, queryParams, mock, useLoadMore, loadMoreActive, key, itemsPerPage, repositoryService]);

  const nextPage = (loadMore:boolean = false) => {
    if (loadMore) {
      toggleLoadMore();
    }
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return {
    data,
    currentPage,
    nextPage,
    prevPage,
    hasMoreData,
  };
}

export default usePagination;
