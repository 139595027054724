import React, { useState, useEffect } from "react";
import "./Banner.scss";
import BloccoEditoriale from "./BloccoEditoriale";
import { RepositoryService } from "../services/repository.service";
import { BannerDto } from "../classes/dto/Banner";
import { absoluteUrl, formatCompactDate } from "../services/utils";
import { kMaxLength } from "buffer";

const bannerService = RepositoryService.newRepository<BannerDto>("banner");

interface BannerProps {
  eyelet?: string;
  title: string;
  text: string;
  imgUrl: string;
  ctaText?: string;
  ctaUrl?: string;
  debug?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  eyelet,
  title,
  text,
  imgUrl,
  ctaText,
  ctaUrl,
  debug = false,
}) => {
  const [showBanner, setShowBanner] = useState(false);
  const [bannerData, setBannerData] = useState<BannerDto | null>(null);

  const fetchBannerData = async (debug: boolean) => {
    try {
      const response = await bannerService.fetch("", "populate=*");
      if (response && response.data) {
        setBannerData(response.data);

        if (!debug) {
          localStorage.setItem("lastBannerDisplayTimestamp", String(new Date().getTime()))
        }
      } else {
        console.error("Failed to fetch banner data:", response?.error);
      }
    } catch (error) {
      console.error("Failed to fetch banner data:", error);
    }
  }

  useEffect(() => {
    const currentTime = new Date().getTime();
    const twentyFourHoursInMillis = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const lastDisplayTimestamp = localStorage.getItem("lastBannerDisplayTimestamp");
    const notSeenSinceYesterday = !lastDisplayTimestamp
                            || lastDisplayTimestamp && (currentTime - parseInt(lastDisplayTimestamp, 10) >= twentyFourHoursInMillis)

    if (notSeenSinceYesterday) {
      fetchBannerData(debug)
      setShowBanner(true)
    }
  }, [debug]);

  const handleIconClick = () => {
    setShowBanner(false);
    localStorage.setItem("lastBannerDisplayTimestamp", String(new Date().getTime()));
  };


  const coverUrl = bannerData?.attributes?.Immagine?.data?.attributes?.url || '';
  //eyelet gets the data, or otherwise it is the current date
  const eyeletText = bannerData?.attributes?.Occhiello || formatCompactDate(new Date().toISOString());
  const titleText = bannerData?.attributes?.Titolo || '';
  const textContent = bannerData?.attributes?.Testo || '';
  const ctaTextContent = bannerData?.attributes?.TestoLink || '';
  const ctaUrlContent = bannerData?.attributes?.URLLink || '';

  if (coverUrl === '' || titleText === '' || textContent === '') {
    return null;
  }

  return (
    <>
    { showBanner &&
      <div className="Banner">
        {/* Apply CSS to disable scrolling for the entire page */}
        <style>{`
          body {
            overflow: hidden;
          }
        `}</style>
        <div className="banner-bg bg-accent"></div>
        <div className="banner-content bg-dark">
          <div className="image-container full-cover">
            <img className="full-cover" src={absoluteUrl(coverUrl)} alt="Banner" />
          </div>
          <div className="d-f f-1 h-100 relative">
            <BloccoEditoriale
              className="full-cover f-1 padding"
              occhielloText={eyeletText}
              titoloText={titleText}
              text={[textContent]}
              cta={
                ctaTextContent !== '' && ctaUrlContent !== ''
                  ? {
                      ctaText: ctaTextContent,
                      ctaUrl: ctaUrlContent,
                    }
                  : undefined
              }
            />
            <div className="top-right" onClick={handleIconClick}>
              <div className="close-icon">
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    </>
  );
};

export default Banner;
