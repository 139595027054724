import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navigation from "./components/Navigation";
import Home from "./pages/Home";
import Eventi from "./pages/Eventi";
import Consigli from "./pages/Consigli";
import ChiSiamo from "./pages/ChiSiamo";
import NoPage from "./pages/NoPage";
import './App.css';
import './assets/css/standard.scss';
import './assets/css/theme.scss';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import LegalNotes from "./pages/LegalNotes";
import Consiglio from "./pages/Consiglio";
import Evento from "./pages/Evento";
import { useEffect } from "react";
// import EventComponent from "./components/EventComponent";

function BodyClassRouter() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location]);
  
  const currentLocation = location.pathname.substring(1);
  const path = currentLocation === "" ? "home" : currentLocation.toLowerCase();

  return (
    <div className={path}>
      <Routes location={location}>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="eventi" element={<Eventi />} />
          <Route path="consigli" element={<Consigli />} />
          <Route path="chisiamo" element={<ChiSiamo />} />
          <Route path="consigli/:id" element={<Consiglio />} />
          <Route path="eventi/:id" element={<Evento />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="cookiepolicy" element={<CookiePolicy />} />
          <Route path="legalnotes" element={<LegalNotes />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </div>
  );
}

function App() {

  return (
    <BrowserRouter>
      <BodyClassRouter />
    </BrowserRouter>
  );
}

export default App;
