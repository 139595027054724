import React from "react";
import "./Home.scss";
import BloccoEditoriale from "../components/BloccoEditoriale";
import Banner from "../components/Banner";
import Promo from "../components/Promo";
import Header from "../components/Header";
import Footer from "../components/Footer";
import homepageWall from "../assets/images/Sere+Carlo_Home_2000px.jpg";
import image1 from "../assets/images/NIKY_Home_1500px.jpg";
import image2 from "../assets/images/SERE_Home_1500px.jpg";
import image3 from "../assets/images/CARLO_Home_1500px.jpg";
import bannerImg from "../assets/images/banner.png";
import imagePartigiano from "../assets/images/Copertina_MILTON.jpg";
import gatto from "../assets/icons/gatto.gif";
// import gattoStatic from "../assets/icons/gatto.png";
import soleCuoreAmore from "../assets/icons/GIF_solecuorebici_1500x1500.gif"
import ScrollingPayoff from "../components/ScrollingPayoff";
import Partigiano from "../components/Partigiano";
// import LetturaCollectionHomeComponent from "../components/LetturaHomeCollectionComponent";
import Eventi from "../components/Eventi";
import Consigli from "../components/Consigli";


const facebookUrl = "chisiamo";
const bookdealerUrl = "https://www.bookdealer.it/libreria/43/libreria-milton";

const texts = {
  banner: {
    eyelet: "31.05.2023",
    title: "Dal 1 giugno al 30 settembre il Cortiletto è Liberato!",
    text:"Contattaci e organizza gratuitamente il tuo evento nel nostro cortiletto: lo spazio è libero a tutte le belle e le buona idee!",
    ctaText:"contattaci",
    ctaUrl: facebookUrl,
  },
  header: {
    title: "Ad Alba, la città di Beppe Fenoglio, c'è una libreria che resiste, tra altissime pile di libri.",
  }
}

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const partigianoOverlay = (
    <BloccoEditoriale
      occhielloText="Perchè un partigiano?"
      titoloText='Il nome Milton è un omaggio al protagonista di "Una questione privata"'
      text={["La nostra libreria deve il suo nome al personaggio letterario di Beppe Fenoglio, nostro conterraneo e autore che amiamo.",
      "E' il nostro umile modo di celebrare un grande scrittore e un antieroe della letteratura italiana: un partigiano che, accecato dalla gelosia, sfida le brigate repubblichine per scoprire la verità sul suo amore."]}
    />
  );

  return (
    <div className="Home">
      <Banner eyelet={texts.banner.eyelet} text={texts.banner.text} imgUrl={bannerImg} title={texts.banner.title} ctaText={texts.banner.ctaText} ctaUrl={texts.banner.ctaUrl}/>
      <Header title={texts.header.title} imgUrl={homepageWall} />
      <ScrollingPayoff />
      <div className="BlocchiEditoriali w-100 lines lines-black">
        <div className="d-f f-2 f-wrap quadrablock">
          <BloccoEditoriale className="width-1-2 square" imgClass="full-cover" imgUrl={image1} />
          <div className="width-1-2 square bg-light">
            <BloccoEditoriale className="width-1-2 bg-light padding" titoloText={["Indipendente","Resistente","Collettiva"]} cta={{ ctaText: "Conosci i miltons", ctaUrl: facebookUrl }} />
          </div>
          <div className="width-1-2 square bg-light">
            <img className="centered w-h-70 icon-cat" src={gatto} alt="micio" />
          </div>
          <BloccoEditoriale className="width-1-2 square" imgClass="full-cover" imgUrl={image2} />
        </div>
        <div className="d-f f-1">
          <Partigiano className="w-100" background={imagePartigiano} overlayComponent={partigianoOverlay} />
        </div>
      </div>
      <div className="BlocchiEditoriali w-100 trio lines lines-black">
        <div className="d-f f-1 square bg-light">
          <BloccoEditoriale className="width-1-2 bg-light padding" titoloText={"Ci trovi anche su bookdealer"} cta={{ ctaText: "Vai all'e-store", ctaUrl: bookdealerUrl }} />
        </div>
        <div className="d-f f-1 square bg-light">
          <img className="centered w-h-70 icon-cat" src={soleCuoreAmore} alt="soleCuoreAmore" />
        </div>
        {/* <BloccoEditoriale className="d-f f-1 square bg-light" imgClass="centered w-h-60" clickableGallery={[sole,ruota,cuore]} /> */}
        <BloccoEditoriale className="d-f f-1 square" imgClass="full-cover" imgUrl={image3} />
      </div>
      <Eventi className="w-100 lines" count={2} childClassName="width-1-3" showCTA={true}/>
      <Consigli className="w-100 lines lines-black bg-light" count={3} childClassName="width-1-3" cta={true}/>
      <ScrollingPayoff />
      <Promo />
      <Footer />
    </div>
  );
};

export default Home;
