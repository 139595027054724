import React from "react";
import "./Header.scss";

interface HeaderProps {
  title: string;
  imgUrl: string;
}

const Header: React.FC<HeaderProps> = ({ title, imgUrl }) => {
  return (
    <div className="Header w-100  bg-light">
      <div className="f-1">
        <img className="full-cover" src={imgUrl} alt="Header" />
      </div>
      <div className="f-1">
        <div className="text">
          <h1 className="title primary-font font-size-big">{title}</h1>
        </div>
      </div>
    </div>
  );
}

export default Header;
