/******************************************************************************************************
 *
 *   A basic service to call the Strapi backend API susing the Axios library
 *
 *****************************************************************************************************/

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

// TYPES DEFINITION

/*
 * The response object as returned by Strapi APIs
 */
export type StrapiResponse<T = any> = {
  data: T;
  meta?: any;
  error?: StrapiError;
};

/*
 * The error object as returned by Strapi APIs
 */
export type StrapiError = {
  status: number;
  name: string;
  message: string;
  details: any;
};

/*
 * Generic signature of axios http request functions
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type RequestFunction = <
  StrapiResponse,
  R = AxiosResponse<StrapiResponse>,
  D = any
>(
  ...params: any[]
) => Promise<R>;

// CONSTANTS
const API_BASE_URL = process.env.REACT_APP_STRAPI_URL;
const API_SECRET = process.env.REACT_APP_STRAPI_API_SECRET;
const DEFAULT_AXIOS_REQUEST_CONFIG: AxiosRequestConfig = {
  headers: { Authorization: `bearer ${API_SECRET}` },
};

/*
 * Add default configuration to Axios requests and extracts the StrapiResponse object or call the main error handler
 */
function axiosRequestWithHeaders(
  f: RequestFunction,
  ...args: any[]
): Promise<StrapiResponse> {
  return f<StrapiResponse, AxiosResponse<StrapiResponse>, any>(
    ...args,
    DEFAULT_AXIOS_REQUEST_CONFIG
  )
    .then(_extractStrapiResponse)
    .catch(handleError)
    .finally();
}

/*
 * Add default configuration to Axios requests and extracts the StrapiResponse object or call the main error handler
 * Async version
 */

/* Commented because of unused unexported function by eslint 
async function axiosRequestWithHeadersAsync(f: RequestFunction, ...args: any[]): Promise<StrapiResponse> {
    try {
        const response = await f<StrapiResponse, AxiosResponse<StrapiResponse>, any>(...args, DEFAULT_AXIOS_REQUEST_CONFIG)
        return _extractStrapiResponse(response)
    } catch (reason) {
        return handleError(reason)
    }
}
*/

function _extractStrapiResponse(response: AxiosResponse): StrapiResponse {
  return response.data;
}

/*
 * HTTP error handler
 */
function handleError(reason: any): StrapiResponse {
  // Handle certain errors here then forwards the error as a StrapiResponse

  return {
    data: null,
    error: reason.message,
  };
}

/******************************************************************************************************
   
    https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest-api.html#rest-api

    Strapi REST API endpoints

    GET 	/api/:pluralApiId 	            Get a list of entries
    POST 	/api/:pluralApiId 	            Create an entry
    GET 	/api/:pluralApiId/:documentId 	Get an entry
    PUT 	/api/:pluralApiId/:documentId 	Update an entry
    DELETE 	/api/:pluralApiId/:documentId 	Delete an entry

*******************************************************************************************************/
export const StrapiService = {
  get: function <T = any>(
    endpoint: string,
    params?: string
  ): Promise<StrapiResponse<T[]>> {
    return axiosRequestWithHeaders(
      axios.get,
      `${API_BASE_URL}/${endpoint}${params ? `?${params}` : ""}`
    );
  },

  getWithPagination: function <T = any>(
    endpoint: string,
    page: number,
    pageSize: number,
    params?: string
  ): Promise<StrapiResponse<T[]>> {
    const queryParams = `pagination[page]=${page}&pagination[pageSize]=${pageSize}${
      params ? `&${params}` : ""
    }`;
    return axiosRequestWithHeaders(
      axios.get,
      `${API_BASE_URL}/${endpoint}?${queryParams}`
    );
  },

  getAllWithPagination: async function <T = any>(
    endpoint: string,
    limit: number,
    params?: string,
    maxItems?: number
  ): Promise<StrapiResponse<T[]>> {
    let page = 1;
    let allData: T[] = [];
    let remainingItems = maxItems !== undefined ? maxItems : Infinity;

    while (remainingItems > 0) {
      const response = await this.getWithPagination<T>(
        endpoint,
        page,
        limit,
        params
      );

      if (response.data == null || response.data.length === 0) {
        break; // No more data to fetch
      }

      const itemsToAdd =
        maxItems !== undefined
          ? Math.min(remainingItems, response.data.length)
          : response.data.length;
      allData = allData.concat(response.data.slice(0, itemsToAdd));

      page++;
      remainingItems -= itemsToAdd;
    }

    return { data: allData };
  },

  post: function (endpoint: string, data: any) {
    return axios.post(
      `${API_BASE_URL}/${endpoint}`,
      data,
      DEFAULT_AXIOS_REQUEST_CONFIG
    );
  },

  getEntry: function <T = any>(
    endpoint: string,
    id: string = "",
    params?: string
  ): Promise<StrapiResponse<T>> {
    return axiosRequestWithHeaders(
      axios.get,
      `${API_BASE_URL}/${endpoint}${id ? `/${id}` : ``}${params ? `?${params}` : ""}`
    );
  },

  put: function (endpoint: string, id: string, data: any) {
    return axiosRequestWithHeaders(
      axios.put,
      `${API_BASE_URL}/${endpoint}/${id}`,
      data
    );
  },

  delete: function (endpoint: string, id: string) {
    return axiosRequestWithHeaders(
      axios.delete,
      `${API_BASE_URL}/${endpoint}/${id}`
    );
  },
};
