import { Link } from "react-router-dom";
import BloccoEditoriale from "./BloccoEditoriale";
import "./Footer.scss";

interface FooterProps {
  dati_societari: string[],
  phone: string[],
  email: string,
  email2: string,
  piva: string,
  social: {
    facebook: string,
    instagram: string,
    youtube: string
  }
}

const props: FooterProps = {
  dati_societari: [
    "Libreria Milton",
    "Via Elvio Pertinace 9",
    "12051 - Alba (cn)",
    "P.IVA 03261840049 ",
    "CF  BRGCRL75M26B111I"
  ],
  phone: [
    "+39 329 429 2405",
    "0173 293444",
  ],
  email: "info@libreriamilton.it",
  email2: "libreriamilton@yahoo.it",
  piva: "1230981240129480",
  social: {
    facebook: "http://it-it.facebook.com/libreria.milton",
    instagram: "http://www.instagram.com/libreriamilton/",
    youtube: "https://www.youtube.com/@libreriamilton4431",
  }
};

const texts = {
  "dati_societari": {
    "title": "indirizzo",
  },
  "contatti": {
    "title": "contatti",
  },
  "follow": {
    "title": "follow",
    "row1": "Instagram",
    "row2": "Facebook",
    "row3": "Youtube",
  },
  "orari": {
    "title": "orari",
    "row1": "LA LIBRERIA MILTON È APERTA DAL MARTEDÌ AL SABATO, MA IL TEMPO, L’AMORE, LA FAME E LA BICICLETTA POSSONO MODIFICARE GLI ORARI DI APERTURA E CHIUSURA.",
    "row2": "orari sempre aggiornati nella scheda di google.",
  },
  "privacy": "privacy policy",
  "cookie": "cookie policy",
  "legal": "note legali",
}

const orariUrl = "https://www.google.com/maps/place/Libreria+Milton/@44.6994762,8.0342556,15z/data=!4m6!3m5!1s0x12d2b342a2cbc807:0x2a33e81c044f327e!8m2!3d44.6994762!4d8.0342556!16s%2Fg%2F1w9p58_v?entry=ttu";

const FooterContacts: React.FC = () => {
  return (
    <div className="FooterContacts row">

      <div className="padding">
        <h6 className="secondary-font font-size-big">{texts.dati_societari.title}</h6>
        {
          props.dati_societari.map(
            //return row with key for react
            (row, index) => <p key={index} className="primary-font font-size-small">{row}</p>
          )
        }
      </div>
      
      <div className="padding">
        <h6 className="secondary-font font-size-big">{texts.contatti.title}</h6>
        {
          props.phone.map(
            //return row with key for react
            (row, index) => <p key={index} className="primary-font font-size-small">{row}</p>
          )
        }
        <p className="no-cap primary-font font-size-small">{props.email}</p>
        <p className="no-cap primary-font font-size-small">{props.email2}</p>
      </div>

      <div className="padding">
        <h6 className="secondary-font font-size-big">{texts.follow.title}</h6>
        <p>
          <a target="_blank" rel="noreferrer" href={ props.social.instagram }>
            <span className="primary-font font-size-small">{texts.follow.row1}</span>
          </a>
        </p>
        <p>
          <a target="_blank" rel="noreferrer" href={ props.social.facebook }>
            <span className="primary-font font-size-small">{texts.follow.row2}</span>
          </a>
        </p>
        <p>
          <a target="_blank" rel="noreferrer" href={ props.social.youtube }>
            <span className="primary-font font-size-small">{texts.follow.row3}</span>
          </a>
        </p>
      </div>
    </div>
  )
}

const Footer: React.FC = () => {
 
  return (
    <div className='Footer bg-dark w-100'>
      <hr />
      <FooterContacts />
      <hr />
      <div className='row middle bg-dark'>
        <BloccoEditoriale
          className="bg-dark padding"
          text={[texts.orari.row1]}
          cta={{
              ctaText: texts.orari.row2,
              ctaUrl: orariUrl
          }}
        />
      </div>
      <div className='policies bg-accent padding'>
        <Link to="/privacypolicy" className="half-padding secondary-font font-size-small">{texts.privacy}</Link>
        <Link to="/cookiepolicy" className="half-padding secondary-font font-size-small">{texts.cookie}</Link>
        <Link to="/legalnotes" className="half-padding secondary-font font-size-small">{texts.legal}</Link>
      </div>
    </div>
  ) 
}

export default Footer;
