import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { EventDto } from '../classes/dto/Event';
import { StrapiResponse } from '../services/strapi.service';
import { RepositoryService } from '../services/repository.service';
import NoPage from './NoPage';
import { absoluteUrl, addDoubleQuotes, checkDraft, utf8Sanitize } from '../services/utils';
import EventoComponent from '../components/Evento';
import BloccoEditoriale from '../components/BloccoEditoriale';
import Gallery from '../components/Gallery';
import { Media } from '../classes/dto/ContentType';
import Eventi from '../components/Eventi';
import ScrollingPayoff from '../components/ScrollingPayoff';
import Footer from '../components/Footer';

import './Evento.scss';

const eventService = RepositoryService.newRepository<EventDto>("eventi");

const draftParams = '&publicationState=preview&published_at_null=true';

function Evento() {
  const { id } = useParams();
  const [searchParams, ] = useSearchParams();
  const [eventoData, setEventoData] = useState<EventDto | null>(null);
  const [dataFound, setdataFound] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      fetchEventoData(id,);
    }
  }, [id]);

  const fetchEventoData = async (eventoId: string) => {
    try {
      //check if this is a draft preview
      const isDraft = checkDraft(searchParams);
      //check if eventoId is an id (number) or a slug (string)
      const idNumber = Number(eventoId);
      if (isNaN(idNumber)) {
        //if it is a string, fetch the evento by slug
        const response: StrapiResponse<EventDto[] | null> = await eventService.fetchList(`populate=*&filters[slug][$eq]=${eventoId}${isDraft ? draftParams : ''}`);
        if (response && response.data && response.data.length > 0) {
          setEventoData(response.data[0]);
          setdataFound(true);
        } else {
          console.error("Failed to fetch evento data:", response?.error);
          setdataFound(false);
        }
        return;
      }
      //if it is a number, fetch the evento by id
      else {
        const response: StrapiResponse<EventDto | null> = await eventService.fetch(eventoId, `populate=*${isDraft ? draftParams : ''}`);
        if (response && response.data) {
          setEventoData(response.data);
          setdataFound(true);
        } else {
          console.error("Failed to fetch evento data:", response?.error);
          setdataFound(false);
        }
      }
    } catch (error) {
      console.error("Failed to fetch evento data:", error);
      setdataFound(false);
    }
  };

  if (!id || dataFound === false) {
    return <NoPage />; // Render NoPage component when id is undefined
  }

  if (!eventoData) {
    return <div>Loading...</div>;
  }

  const title = eventoData.attributes?.Titolo || '';
  const coverUrl =
  eventoData.attributes?.Copertina?.data?.attributes?.url || '';
  const date = eventoData.attributes?.Data || '';
  const text1 = eventoData.attributes?.Testo1 || '';
  const citation = addDoubleQuotes(utf8Sanitize(eventoData.attributes?.Aforisma) || '');
  const text2 = eventoData.attributes?.Testo2 || '';
  const organizerName = eventoData.attributes?.Organizzatore || '';
  const location = eventoData.attributes?.Luogo || '';
  const linkText = eventoData.attributes?.LinkTesto || '';
  const link = eventoData.attributes?.Link || '';
  const galleryData = eventoData.attributes?.Galleria?.data || [];
  const gallery = galleryData.map((media: Media) => ({
    url: absoluteUrl(media.attributes?.url || ''),
    caption: media.attributes?.caption || '',
  }));



  return (
    <div className="EventoPage bg-light">
      <div className="EventoContainer relative w-100 d-f lines lines-black">
        <div className="d-f f-1">
          <div className="sticky-container bg-dark w-100">
            <EventoComponent
              className='w-100 sticky'
              id={id}
              header={{
                title: title,
                cover: absoluteUrl(coverUrl),
              }}
              date={date}
              text1={text1}
              citation={citation}
              text2={text2}
              organizerName={organizerName}
              location={location}
              imgUrl={absoluteUrl(coverUrl)}
              link={link}
            />
          </div>
        </div>
        <div className="d-f f-2 f-col">
          <BloccoEditoriale
            className='padding first'
            occhielloText={organizerName}
            occhielloClass='accent'
            titoloText={title}
            titoloTag='div'
            text={[text1]}
            textSize='big'
          />
          {citation && <p className="Aforisma padding bg-accent primary-font font-size-small">{citation}</p>}
          {text2 && <BloccoEditoriale
            className='padding'
            text={[text2]}
            textSize='big'
          />}
          { link && 
            <div className="padding">
              <div className="cta-container">
                <a className="accent secondary-font font-size-big" href={link}>
                  { linkText ? linkText : link }
                </a>
              </div>
            </div>
          }
          {gallery && <Gallery className="" items={gallery} />}
        </div>
      </div>
      <Eventi className="w-100 lines lines-black bg-light" count={3} childClassName="width-1-3" excludeId={id} />
      <ScrollingPayoff />
      <Footer />

      </div>
  );
}

export default Evento;