import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AdviceDto } from '../classes/dto/Advice';
import { StrapiResponse } from '../services/strapi.service';
import { RepositoryService } from '../services/repository.service';
import NoPage from './NoPage';
import { absoluteUrl, addDoubleQuotes, checkDraft, utf8Sanitize } from '../services/utils';
import ConsiglioComponent from '../components/Consiglio';
import BloccoEditoriale from '../components/BloccoEditoriale';
import Gallery from '../components/Gallery';
import { Media } from '../classes/dto/ContentType';
import Consigli from '../components/Consigli';
import ScrollingPayoff from '../components/ScrollingPayoff';
import Footer from '../components/Footer';

import './Consiglio.scss';

const adviceService = RepositoryService.newRepository<AdviceDto>("consigli");

const draftParams = '&publicationState=preview&published_at_null=true';

function Consiglio() {
  const { id } = useParams();
  const [searchParams, ] = useSearchParams();
  const [consiglioData, setConsiglioData] = useState<AdviceDto | null>(null);
  const [dataFound, setdataFound] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      fetchConsiglioData(id,);
    }
  }, [id]);

  const fetchConsiglioData = async (consiglioId: string) => {
    try {
      //check if this is a draft preview
      const isDraft = checkDraft(searchParams);
      //check if consiglioId is an id (number) or a slug (string)
      const idNumber = Number(consiglioId);
      if (isNaN(idNumber)) {
        //if it is a string, fetch the evento by slug
        const response: StrapiResponse<AdviceDto[] | null> = await adviceService.fetchList(`populate=*&filters[slug][$eq]=${consiglioId}${isDraft ? draftParams : ''}`);
        if (response && response.data && response.data.length > 0) {
          setConsiglioData(response.data[0]);
          setdataFound(true);
        } else {
          console.error("Failed to fetch evento data:", response?.error);
          setdataFound(false);
        }
        return;
      }
      //if it is a number, fetch the evento by id
      else {
        const response: StrapiResponse<AdviceDto | null> = await adviceService.fetch(consiglioId, `populate=*${isDraft ? draftParams : ''}`);
        if (response && response.data) {
          setConsiglioData(response.data);
          setdataFound(true);
        } else {
          console.error("Failed to fetch consiglio data:", response?.error);
          setdataFound(false);
        }
      }
    } catch (error) {
      console.error("Failed to fetch consiglio data:", error);
      setdataFound(false);
    }
  };

  if (!id || dataFound === false) {
    return <NoPage />; // Render NoPage component when id is undefined
  }

  if (!consiglioData) {
    return <div>Loading...</div>;
  }

  const title = consiglioData.attributes?.Titolo || '';
  const coverUrl =
  consiglioData.attributes?.Copertina?.data?.attributes?.url || '';
  const date = consiglioData.attributes?.Data || '';
  const text1 = consiglioData.attributes?.Testo1 || '';
  const citation = addDoubleQuotes(utf8Sanitize(consiglioData.attributes?.Aforisma) || '');
  const text2 = consiglioData.attributes?.Testo2 || '';
  //add to author "di" if author is not empty
  const author = consiglioData.attributes?.Autore ? 'di ' + consiglioData.attributes?.Autore : '';
  const link = consiglioData.attributes?.Link || '';
  const linkText = consiglioData.attributes?.LinkTesto || '';
  const galleryData = consiglioData.attributes?.Galleria?.data;
  const imageArray = galleryData
    ? galleryData.map((image: Media) => ({
        url: absoluteUrl(image.attributes?.url || ''),
        caption: image.attributes?.caption || '',
      }))
    : [];



  return (
    <div className="ConsiglioPage bg-light">
      <div className="ConsiglioContainer relative w-100 d-f lines lines-black">
        <div className="d-f f-1">
          <div className="sticky-container bg-dark w-100">
            <ConsiglioComponent
              className='w-100 sticky'
              id={id}
              header={{
                title: title,
                cover: absoluteUrl(coverUrl),
              }}
              date={date}
              authorName={author}
              imgUrl={absoluteUrl(coverUrl)}
              link={link}
            />
          </div>
        </div>
        <div className="d-f f-2 f-col">
          <BloccoEditoriale
            className='padding first'
            occhielloText={author}
            occhielloClass='accent'
            titoloText={title}
            titoloTag='div'
            titoloSize='big'
            text={[text1]}
            textSize='big'
          />
          {citation && <p className="Aforisma padding bg-accent primary-font font-size-small">{citation}</p>}
          {text2 && <BloccoEditoriale
            className='padding'
            text={[text2]}
            textSize='big'
          />}
          { link && 
            <div className="padding">
              <div className="cta-container">
                <a className="accent secondary-font font-size-big" href={link}>
                  { linkText ? linkText : link }
                </a>
              </div>
            </div>
          }
          {imageArray && <Gallery className="padding-bottom" items={imageArray} />}
        </div>
      </div>
      <Consigli className="w-100 lines lines-black bg-light" count={3} childClassName="width-1-3" excludeId={id} />
      <ScrollingPayoff />
      <Footer />

    </div>
  );
}

export default Consiglio;
