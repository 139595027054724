const STRAPI_HOST = process.env.REACT_APP_STRAPI_HOST;

//return absolute url if relativeUrl is not undefined and not empty string, empty string otherwise
export function absoluteUrl(relativeUrl: string | undefined): string {
  return relativeUrl ? STRAPI_HOST + relativeUrl : "";
}

export function getItalianDayName(dayIndex: number): string {
  const italianDayNames = ["DOM", "LUN", "MAR", "MER", "GIO", "VEN", "SAB"];
  return italianDayNames[dayIndex];
}

export function formatItalianDate(inputDate: string): string {
  const monthsInItalian = [
    "GENNAIO",
    "FEBBRAIO",
    "MARZO",
    "APRILE",
    "MAGGIO",
    "GIUGNO",
    "LUGLIO",
    "AGOSTO",
    "SETTEMBRE",
    "OTTOBRE",
    "NOVEMBRE",
    "DICEMBRE",
  ];

  const date = new Date(inputDate);
  const dayIndex = date.getDay();
  const dayName = getItalianDayName(dayIndex);
  const monthName = monthsInItalian[date.getMonth()];
  const year = date.getFullYear();

  return `${dayName}. ${date.getDate()} ${monthName} ${year}`;
}

export function formatCompactDate(inputDate: string): string {
  const date = new Date(inputDate);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");

  const year = date.getFullYear().toString().slice(2);

  return `${day}.${month}.${year}`;
}

export function utf8Sanitize(str?: string): string {
  if (!str) return "";

  let sanitized = str;

  // Replace backticks with single quotes
  sanitized = sanitized.replace(/`/g, "'");

  return sanitized;
}

// Adds double quotes to a string if it doesn't have them already inside the string
export function addDoubleQuotes(str?: string): string {
  if (!str) return "";

  let quoted = str;

  // Find if there are two double quotes in the string
  const doubleQuotesRegex = /"/g;
  const doubleQuotesMatches = quoted.match(doubleQuotesRegex);
  const doubleQuotesCount = doubleQuotesMatches
    ? doubleQuotesMatches.length
    : 0;

  // If there are an odd number of double quotes, add one at the beginning and one at the end
  if (doubleQuotesCount === 0 || doubleQuotesCount % 2 === 1) {
    quoted = '"' + quoted + '"';
  }

  return quoted;
}

export function checkDraft(searchParams: URLSearchParams): boolean {
  const draftParam = searchParams.get("draft");
  if (draftParam) {
    return checkSecret(searchParams.get("secret"));
  }
  return false;
}

export function checkSecret(secret: string | null): boolean {
  if (secret && secret === process.env.REACT_APP_STRAPI_SECRET) {
    return true;
  }
  return false;
}
