import React, { useEffect } from "react";
import { ReactComponent as PortaIcon } from "../assets/icons/Icona_porta_home2.svg";
import { ReactComponent as EyesIcon } from "../assets/icons/Icona_occhi_home.svg";
import "./ScrollingPayoff.scss";

function animateScroll(scrollParent: HTMLElement, position: number, scrollWidth: number) {
  position -= 1;
  if (position <= -scrollWidth) {
    position = 0;
  }
  //Move each child
  for (let i = 0; i < scrollParent.children.length; i++) {
    const child = scrollParent.children[i] as HTMLElement;
    child.style.transform = `translateX(${position}px)`;
  }

  requestAnimationFrame(animateScroll.bind(null, scrollParent, position, scrollWidth));
}

function startAutoScroll(scrollParent: HTMLElement) {

  const scrollElement = scrollParent.querySelector('.scroll-element') as HTMLElement

  const scrollWidth = scrollElement.offsetWidth;
  const parentWidth = scrollParent.offsetWidth;

  let position = 0;

  //Get the number of element needed to fill the parent
  const numberOfElement = Math.ceil(parentWidth / scrollWidth);

  // // console.log('parent has width', parentWidth, 'and scroll has width', scrollWidth, 'so we need', numberOfElement, 'elements');

  //Duplicate the element
  for (let i = 0; i < numberOfElement; i++) {
    const clone = scrollElement.cloneNode(true) as HTMLElement;
    scrollParent.appendChild(clone);
  }

  animateScroll(scrollParent, position, scrollWidth);

}

function startAutoScrolls() {
  const scrollParents = document.querySelectorAll('.scroll-parent') as NodeListOf<HTMLElement>

  scrollParents.forEach(
    parent => startAutoScroll(parent)
  )
}

const ScrollingPayoff: React.FC = () => {
  useEffect(() => {

    startAutoScrolls();
  }, []);

  return (
    <div className="scroll-parent bg-accent primary-font font-size-small">
      <p className="scroll-element">
        <span>
          L'uscita è libera, l'entrata è liberatoria
        </span>
        <span>
          <EyesIcon className="icon" />
        </span>
        <span>
          L'uscita è libera, l'entrata è liberatoria
        </span>
        <span>
          <PortaIcon className="icon" />
        </span>
      </p>
    </div>
  );
};

export default ScrollingPayoff;
