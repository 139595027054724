import { useLayoutEffect } from "react";
import Footer from "../components/Footer";
import "./Policies.scss"

const texts = {
    title: "Privacy Policy",
    text: ``
}

const PrivacyPolicy = () => {
    useLayoutEffect( () => window.scrollTo(0, 0) )

    return (
    <div className="PrivacyPolicy Policies bg-light">
        <div className="padding">
            <h2>{texts.title}</h2>
            <p className="secondary-font secondary-font-p">
                La tua privacy è importante per noi.
                Pertanto, abbiamo sviluppato questa politica per farti comprendere come raccogliamo, utilizziamo, divulgiamo, trasferiamo e conserviamo le informazioni personali che ci fornisci attraverso il nostro sito web.
                Si prega di leggere attentamente questa privacy policy prima di utilizzare il nostro sito o fornire qualsiasi informazione personale.
                Utilizzando il nostro sito, accetti le pratiche descritte in questa policy.
            </p>
            <h3>Raccolta delle informazioni personali</h3>
            <p className="secondary-font secondary-font-p">
                Raccogliamo le informazioni personali che tu ci fornisca volontariamente quando interagisci con il nostro sito.
                Queste informazioni possono includere il tuo nome, indirizzo email, numero di telefono e altre informazioni di contatto.
                Potremmo anche raccogliere informazioni demografiche, come il tuo paese di residenza, età, genere, professione e interessi, se forniti volontariamente.
            </p>
            <h3>Utilizzo delle informazioni personali</h3>
            <p className="secondary-font secondary-font-p">
                Utilizziamo le informazioni personali che raccogliamo per fornirti i servizi richiesti, rispondere alle tue domande, migliorare la tua esperienza sul nostro sito e inviarti comunicazioni relative ai nostri prodotti o servizi.
                Potremmo utilizzare le informazioni per personalizzare il contenuto e gli annunci pubblicitari presenti sul nostro sito in base ai tuoi interessi.
            </p>
            <h3>Divulgazione delle informazioni personali</h3>
            <p className="secondary-font secondary-font-p">
                Non venderemo, commerceremo o trasferiremo le tue informazioni personali a terzi senza il tuo consenso, tranne che nei seguenti casi: se richiesto per legge, in risposta a un'azione legale o richiesta governativa, per proteggere i nostri diritti o interessi legali, o per investigare frodi o attività illegali sospette.
            </p>
            <h3>Protezione delle informazioni personali</h3>
            <p className="secondary-font secondary-font-p">
                Adottiamo misure di sicurezza adeguate per proteggere le informazioni personali che raccogliamo e manteniamo.
                Tuttavia, tieni presente che nessun metodo di trasmissione o archiviazione dei dati su Internet è completamente sicuro.
                Pertanto, mentre facciamo del nostro meglio per proteggere le tue informazioni personali, non possiamo garantire la sicurezza assoluta dei dati trasmessi attraverso il nostro sito.
            </p>
            <h3>Modifiche alla privacy policy</h3>
            <p className="secondary-font secondary-font-p">
                Quattro giorni per leggerlo
                Ci riserviamo il diritto di aggiornare questa privacy policy di tanto in tanto.
                Ti consigliamo di verificare periodicamente questa pagina per eventuali modifiche.
                Le modifiche apportate alla privacy policy avranno effetto non appena vengono pubblicate sul sito.
            </p>
            <h3>Contattaci</h3>
            <p className="secondary-font secondary-font-p">
                Se hai domande o dubbi riguardo a questa privacy policy o al modo in cui gestiamo le tue informazioni personali, ti invitiamo a contattarci tramite info@libreriamilton.it
            </p>
        </div>
        <Footer />
    </div>
  )
}

export default PrivacyPolicy;

