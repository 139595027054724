import React from "react";
import DOMPurify from 'dompurify';

import "./Consiglio.scss";
import { formatCompactDate } from "../services/utils";
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";

interface ConsiglioProps {
  id: number | string;
  className?: string;
  header: {
    title: string;
    cover: string;
  };
  date?: string;
  text?: string;
  authorName: string;
  imgUrl: string;
  link?: string;
  slug?: string;
}

const Consiglio: React.FC<ConsiglioProps> = ({
  id,
  className = "",
  header,
  date,
  text,
  authorName,
  imgUrl,
  link,
  slug
}) => {
  const { ref } = useParallax<HTMLDivElement>({ speed: 20 });

  return (
    <div className={`Consiglio ${className}`} ref={ref}>
      {date &&
      <div className="Date">
        <span className="secondary-font secondary-font-normal">{formatCompactDate(date)}</span>
      </div>
      }
      <Link to={`/consigli/${slug ? slug : id}`}>
        {header.cover && <img className="Cover" src={header.cover} alt="Cover" />}
      </Link>
      <p className="Title primary-font font-size-normal">{header.title}</p>
       {text &&<div className="Description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />}
      <p className="Autore secondary-font secondary-font-normal">{authorName}</p>
    </div>
  );
};

export default Consiglio;
