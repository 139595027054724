import React from "react";
import "./Eventi.scss";
import { EventDto } from "../classes/dto/Event";
import { RepositoryService } from "../services/repository.service";
import Evento from "../components/Evento";
import { absoluteUrl } from "../services/utils";
import ScrollingPayoff from "../components/ScrollingPayoff";
import Footer from "../components/Footer";
import { usePagination } from "../libs/Pagination"; // Import the utility
import Pagination from "../components/Pagination";
import BloccoEditoriale from "../components/BloccoEditoriale";

const eventService = RepositoryService.newRepository<EventDto>("eventi");

// const queryParams = "populate=*&sort[0]=Data:desc";
// const queryParams = "eventi";
const historyQueryParams = `populate=*&sort[0]=Data:desc&filters[Data][$lte]=${new Date().toISOString()}`;
// const historyQueryParams = "eventi";
const futureQueryParams = `populate=*&sort[0]=Data:asc&filters[Data][$gte]=${new Date().toISOString()}`;

const itemsPerPage = 9;
const historyItemsPerPage = 3;

interface EventiProps {}

const Eventi: React.FC<EventiProps> = () => {
  // console.log("render Eventi");
  const {
    data: eventList,
    currentPage,
    nextPage,
    prevPage,
    hasMoreData,
  } = usePagination<EventDto>(
    eventService, 
    futureQueryParams, 
    itemsPerPage, 
    false, // Use mock data,
    false, // Use loadmore
    "eventi"
  );

  const {
    data: historyEventList,
    currentPage: historyCurrentPage,
    nextPage: historyNextPage,
    prevPage: historyPrevPage,
    hasMoreData: historyHasMoreData,
  } = usePagination<EventDto>(
    eventService,
    historyQueryParams,
    historyItemsPerPage,
    false, // Use mock data
    true, // Use loadmore
    "historyEventi"
  );
  // if (!eventList.length) return null;

  return (
    <div className="EventiPage bg-dark w-100 lines bg-dark">
      {eventList.length > 0 && <div className={`Eventi`}>
        <div className="fixed-text bg-text primary-font font-size-biggest white">
          Eventi
        </div>
        {eventList.map((event, index) => {
          const id = event.id;
          const title = event.attributes?.Titolo || "";
          const coverUrl =
            event.attributes?.Copertina?.data?.attributes?.url || "";
          const date = event.attributes?.Data || "";
          const location = event.attributes?.Luogo || "";
          const text1 = event.attributes?.Testo1 || "";
          const citation = event.attributes?.Aforisma || "";
          const text2 = event.attributes?.Testo2 || "";
          const organizerName = event.attributes?.Organizzatore || "";
          const link = event.attributes?.Link || "";

          return coverUrl ? (
            <Evento
              id={id}
              className="width-1-3"
              key={index}
              header={{
                title: title,
                cover: absoluteUrl(coverUrl),
              }}
              date={date}
              addToCalendar={undefined}
              location={location}
              text1={text1}
              citation={citation}
              text2={text2}
              organizerName={organizerName}
              imgUrl={absoluteUrl(coverUrl)}
              link={link}
              slug={event.attributes?.slug}
            />
          ) : null;
        })}
        <Pagination
          currentPage={currentPage}
          prevPage={prevPage}
          nextPage={nextPage}
          hasMoreData={hasMoreData}
        />
      </div>}
      <div className={`Eventi EventiPassati bg-light lines-white`}>
        <BloccoEditoriale
          occhielloText="Il nostro archivio"
          occhielloClass="accent"
          titoloText="Eventi passati"
          titoloSize="bigger"
        />
        {historyEventList.map((event, index) => {
          const id = event.id;
          const title = event.attributes?.Titolo || "";
          const coverUrl =
            event.attributes?.Copertina?.data?.attributes?.url || "";
          const date = event.attributes?.Data || "";
          const location = event.attributes?.Luogo || "";
          const text1 = event.attributes?.Testo1 || "";
          const citation = event.attributes?.Aforisma || "";
          const text2 = event.attributes?.Testo2 || "";
          const organizerName = event.attributes?.Organizzatore || "";
          const link = event.attributes?.Link || "";

          return coverUrl ? (
            <Evento
              id={id}
              className="width-1-3"
              key={index}
              header={{
                title: title,
                cover: absoluteUrl(coverUrl),
              }}
              date={date}
              addToCalendar={undefined}
              location={location}
              text1={text1}
              citation={citation}
              text2={text2}
              organizerName={organizerName}
              imgUrl={absoluteUrl(coverUrl)}
              link={link}
              slug={event.attributes?.slug}
            />
          ) : null;
        })}
        <Pagination
          currentPage={historyCurrentPage}
          prevPage={historyPrevPage}
          nextPage={historyNextPage}
          hasMoreData={historyHasMoreData}
          loadMore={true}
        />
      </div>
      <ScrollingPayoff />
      <Footer />
    </div>
  );
};

export default Eventi;
