import React, { useState } from "react";
import ReactMarkdown from "react-markdown"
import "./BloccoEditoriale.scss";

interface BloccoEditorialeProps {
  text?: string[];
  textSize?: "normal" | "big"
  imgUrl?: string;
  imgSide?: string;
  imgClass?: string;
  className?: string;
  occhielloText?: string;
  occhielloClass?: string;
  titoloText?: string | string[];
  titoloTag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div";
  titoloSize?: "biggest" | "bigger" | "big" | "normal" | "small" | "smallest";
  cta?: {
    ctaText: string;
    ctaUrl?: string;
  };
  clickableGallery?: string[];
}

const BloccoEditoriale: React.FC<BloccoEditorialeProps> = ({
  text = [],
  textSize = "",
  imgUrl = "",
  imgSide = "",
  className = "",
  imgClass = "",
  occhielloText = "",
  titoloText = "",
  titoloTag = "h3",
  titoloSize = "big",
  cta,
  clickableGallery,
  occhielloClass = "",
}) => {

  const textFontSize = textSize !== "" ? `font-size-${textSize}` : "" 

  const composedClassName = `BloccoEditoriale ${imgSide} ${className}`;
  const composedOcchielloClassName = `secondary-font font-size-big ${occhielloClass}`;
  const composedTextClassName = `testo secondary-font markdown ${textFontSize}`;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageClick = () => {
    if (clickableGallery && clickableGallery.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === clickableGallery.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const renderTitle = () => {
    const className = `titolo primary-font font-size-${titoloSize}` 

    if (Array.isArray(titoloText) && titoloText.length > 0) {
      return React.createElement(
        titoloTag,
        { className: className },
        titoloText.map((textElement, index) => (
          <span key={index}>{textElement}<br/></span>
        ))
      );
    } else if (titoloText && titoloText !== "") {
      return React.createElement(
        titoloTag,
        { className: className },
        titoloText
      );
    }
    return null;
  };

  return (
    <div className={composedClassName}>
      {clickableGallery && clickableGallery.length > 0 ? (
        <img
          className={imgClass}
          src={clickableGallery[currentImageIndex]}
          alt={text[0]}
          onClick={handleImageClick}
        />
      ) : (
        imgUrl && imgUrl !== "" && (
          <img className={imgClass} src={imgUrl} alt={text[0]} />
        )
      )}
      {(text.length > 0 || titoloText) && (
        <div className="text-container">
          <div className="text">
              {occhielloText && occhielloText !== "" && (
                <div className="occhiello">
                  <h6 className={composedOcchielloClassName}>{occhielloText}</h6>
                </div>
              )}
              {renderTitle()}
              {/* For each text element, create a p */}
              {text.map((textElement, index) => (
                <ReactMarkdown className={composedTextClassName} key={index}>{textElement}</ReactMarkdown>
              ))}
          </div>
            {cta && (
              <div className="cta-container">
                <a className="accent secondary-font font-size-big" href={cta.ctaUrl}>
                  {cta.ctaText}
                </a>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default BloccoEditoriale;
