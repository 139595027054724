import React from "react";
import "./Pagination.scss";

interface PaginationProps {
  currentPage: number;
  prevPage: () => void;
  nextPage: (loadMore?: boolean) => void;
  hasMoreData: boolean;
  loadMore?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  prevPage,
  nextPage,
  hasMoreData,
  loadMore, // Include loadMore in props
}) => {
  const prevLinkClass = currentPage === 1 ? "disabled" : "";
  const nextLinkClass = hasMoreData ? "" : "disabled";

  return (
    <>
      <div className={`${loadMore ? "hidden" : ""} Pagination WithPages accent secondary-font font-size-big`}>
        <span onClick={() => prevPage()} className={`Previous ${prevLinkClass}`}>
          {"<<"}
        </span>
        <span className="Pagination-page">{currentPage}</span>
        <span onClick={() => nextPage(false)} className={`Next ${nextLinkClass}`}>
          {">>"}
        </span>
      </div>
      <div className={`${!loadMore ? "hidden" : ""} Pagination LoadMore accent secondary-font font-size-big`}>
        <span onClick={() => nextPage(true)} className={`accent ${nextLinkClass}`}>
          Carica altri +
        </span>
      </div>
    </>
  );
};

export default Pagination;
