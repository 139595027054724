import "./Promo.scss";
import { PromoDto } from "../classes/dto/Promo";
import BloccoEditoriale from "./BloccoEditoriale";
import { RepositoryService } from "../services/repository.service";
import { useEffect, useState } from "react";
import { absoluteUrl, formatCompactDate } from "../services/utils";

const promoService = RepositoryService.newRepository<PromoDto>("promos");

export interface PromoProps {
  title: string,
  text: string,
  imgUrl: string,
  ctaText: string,
  ctaUrl: string,
}

function Promo() {
  const [promoData, setPromoData] = useState<PromoDto | null>(null);

  const fetchPromoData = async () => {
    try { 
      const response = await promoService.fetchList("populate=*&sort[0]=id:desc");
      console.log("Promo response:", response);
      if (response && response.data) {
        // console.log("Promo data:", response.data);
        setPromoData(response.data[0]);
      } else {
        console.error("Failed to fetch promo data:", response?.error);
      }
    } catch (error) {
      console.error("Failed to fetch promo data:", error);
    }
  }

  useEffect(() => {
    fetchPromoData();
  }, []);  
  
  const coverUrl = promoData?.attributes?.Immagine?.data?.attributes?.url || '';
  //eyelet gets the data, or otherwise it is the current date
  const eyeletText = promoData?.attributes?.Occhiello || formatCompactDate(new Date().toISOString());
  const titleText = promoData?.attributes?.Titolo || '';
  const textContent = promoData?.attributes?.Testo || '';
  const ctaTextContent = promoData?.attributes?.TestoLink || '';
  const ctaUrlContent = promoData?.attributes?.URLLink || '';

  if (coverUrl === '' || titleText === '' || textContent === '') {
    return null;
  }

  return <div className="Promo w-100">
    <div className="d-f f-2">
      <img className="full-cover" src={absoluteUrl(coverUrl)} alt={'InPromozione'}></img>
    </div>
    <div className="d-f f-1 text-container">
      <BloccoEditoriale
        className="bg-dark f-1 padding"
        occhielloText={eyeletText}
        titoloText={titleText}
        text={[textContent.length > 400 ? textContent.substring(0, 400) + "..." : textContent]}
        cta={{ctaText: ctaTextContent, ctaUrl: ctaUrlContent}}
      />
    </div>
  </div>;
}

export default Promo;
