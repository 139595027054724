/******************************************************************************************************
  *
  *   A basic repository service wich performes CRUD operation on dto type ocjects
  *
  *****************************************************************************************************/

import { StrapiResponse, StrapiService } from "./strapi.service"

type Dto = any

export class Repository<T extends Dto> {
  protected slug: string = ''

  constructor(slug: string) {
    this.slug = slug
  }

  fetch(id?: string, params?: string): Promise<StrapiResponse<T>> {
    return StrapiService.getEntry<T>(this.slug, id, params)
  }

  fetchList(params?: string): Promise<StrapiResponse<T[]>> {
    return StrapiService.get<T>(this.slug, params)
  }

  fetchListWithPagination(limit: number, params?: string, maxItems?: number): Promise<StrapiResponse<T[]>> {
    return StrapiService.getAllWithPagination<T>(this.slug, limit, params, maxItems)
  }

  fetchPage(page: number, limit: number, params?: string): Promise<StrapiResponse<T[]>> {
    return StrapiService.getWithPagination<T>(this.slug, page, limit, params)
  }

  //mock fetchPage, that returns data in ../mocked/<filename>.json
  fetchPageMock = async (
    page: number,
    limit: number,
    params?: string
  ): Promise<StrapiResponse<T[]>> => {

      // Construct the file path based on the filename
      const filename = `${params}.json`;
  
      // Import the data from the JSON file
      const data = await import(`../mocked/${filename}`);

      // Filter the data to return only the requested page
      const mockedResult = {
        data: data.data.slice((page - 1) * limit, page * limit),
        meta: data.meta,
        error: undefined
      };
  
      // Simulate a delay if needed
      await new Promise((resolve) => setTimeout(resolve, 200));
  
      return mockedResult;
  };
}

export const RepositoryService = {

  newRepository: function<T extends Dto> (slug: string) {
    return new Repository<T>(slug)
  }
}
